import "./Footer.scss";
import React from "react";
import Brand from "../brand/Brand";
import { Link } from "react-router-dom";
import Covid19 from '../disclaimer/Covid19'

import SocialIcons from "../social-icons/SocialIcons";
import FooterList from "./FooterList";


const date = new Date();
const year = date.getFullYear()
export default function NavBar() {
  
  return (
    <React.Fragment>
      <footer className="footer">
        <Brand color="dark" />

        <FooterList padding="large">
          <li>
            <Link to="/" className="nav-link p-t-5 p-b-0">
              Home
            </Link>
          </li>
          <li>
            <Link to="/the-fat-bastards" className="nav-link p-t-5 p-b-0">
              The FAT <i style={{ lineHeight: 0 }}>bastards</i>
            </Link>
          </li>
          <li>
            <a href="/#vib" className="nav-link p-t-5 p-b-0">
              VIB
            </a>
          </li>
          <li>
            <Link to="/fat-inspirations" className="nav-link p-t-5 p-b-0">
              FAT <i>Inspirations</i>
            </Link>
          </li>
          {/* <li>
            <Link to="/social-feed" className="nav-link p-t-5 p-b-0">
              Social Feed
            </Link>
          </li> */}
          <li>
            <Link to="/contact-us" className="nav-link p-t-5 p-b-0">
              Get In Touch
            </Link>
          </li>
        </FooterList>
        <br />
        <SocialIcons />
        <br />
        <FooterList padding="small">
          <li>
            <Link to="/" className="nav-link p-t-5 p-b-0">
              Copyrights © {year} FAT <i>bastard</i>
            </Link>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.optimalonline.co.za/"
              className="nav-link p-t-5 p-b-0"
               rel="noopener noreferrer"
            >
              Powered by Optimal Online
            </a>
          </li>
          <li>
            <div className="nav-link p-t-5 p-b-0">All rights reserved</div>
          </li>
          <li>
            <Link to="/terms-and-conditions" className="nav-link p-t-5 p-b-0">
              Terms & Conditions
            </Link>
          </li>
          <li>
            <a href="/resources/PRIVACY-POLICY-ROBERTSON-WINERY.pdf" download="Fat bastard Privacy Policy" className="nav-link p-t-5 p-b-0">
              Privacy Policy
            </a>
          </li>
        </FooterList>

        <a href="https://aware.org.za/" target="_blank" rel="noopener noreferrer">
          <img
            src="../../../resources/img/aware-logo-white-web.png"
            alt="aware-logo"
            style={{ maxWidth: "180px", marginTop: "30px" }}
          />
        </a>
      </footer>
      <Covid19 className="hr_covid-19" />
    </React.Fragment>
  );
}
